<template>
<main id="app">

<hr>
<div>
    Every single Piotr from Wrocław
  <table>
    <thead>
      <tr>
        <th class="th-class"
          :class="sortedClass('Name')"
          @click="sortBy('Name')"
        >
          Imie
        </th>
        <th class="th-class"
          :class="sortedClass('LastName')"
          @click="sortBy('LastName')"
        >
          Nazwisko
        </th>
        <th class="th-class"
          :class="sortedClass('City')"
          @click="sortBy('City')"
        >
          Miasto
        </th>
                <th class="th-class"
          :class="sortedClass('Email')"
          @click="sortBy('Email')"
        >
          Email
        </th>
        
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in sortedItems"
        :key="item.Id"
      >
        <td>{{item.Name}}</td>
        <td>{{item.LastName}}</td>
        <td>{{item.City}}</td>
        <td>{{item.Email}}</td>
      </tr>
    </tbody>
  </table>
</div>

<hr>
<div>
    Every single Reservation created
  <table>
    <thead>
      <tr>
        <th class="th-class"
          :class="sortedClass('ReservationNumber')"
          @click="sortBy('ReservationNumber')"
        >
          Numer Rezerwacji
        </th>
        <th class="th-class"
          :class="sortedClass('Price')"
          @click="sortBy('Price')"
        >
          Cena
        </th>
        <th class="th-class"
          :class="sortedClass('CreationDate')"
          @click="sortBy('CreationDate')"
        >
          Data Stwordzenia
        </th>
                <th class="th-class"
          :class="sortedClass('StartDate')"
          @click="sortBy('StartDate')"
        >
          Data Rozpoczęscia

        </th>
                <th class="th-class"
          :class="sortedClass('EndDate')"
          @click="sortBy('EndDate')"
        >
          Data zakończenia
          
        </th>
        
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in sortedReservation"
        :key="item.ID"
      >
        <td>{{item.ReservationNumber}}</td>
        <td>{{item.Price}}</td>
        <td>{{item.CreationDate}}</td>
        <td>{{item.StartDate}}</td>
        <td>{{item.EndDate}}</td>
      </tr>
    </tbody>
  </table>
  </div>
  <hr>
</main>
<div style="width:400px; margin:auto">
<p><label for="">Imie</label>  
<input v-model="AddName" placeholder="Imie"></p>
<p><label for="">Nazwisko</label>  
<input v-model="AddLastName" placeholder="Nazwisko"></p>
<p><label for="">Email</label>  
<input v-model="AddEmail" placeholder="Email"></p>
<p><label for="">BirthDate</label>  
<input type="date" v-model="AddBirthDate" placeholder=""></p>
<p><label for="">Post code</label>  
<input type="number" v-model="AddPostCode" placeholder="Post code"></p>
<p><label for="">Adress</label>  
<input v-model="AddAddress" placeholder="Adress"></p>
<p><label for="">City</label>  
<input v-model="AddCity" placeholder="City"></p>
<p><label for="">Phone</label>  
<input v-model="AddPhone" maxlength="9" type="number" placeholder="Phone"></p>
<p><label  for="">Reservation Number</label>  
<input v-model="AddReservationNumber" type="number" maxlength="10" placeholder="Reservation Number"></p>
<p><label  for="">Price</label>  
<input v-model="AddPrice"  type="number" placeholder="Price"></p>
<p><label for="">Start Date</label>  
<input type="date" v-model="AddStartDate" placeholder=""></p>
<p><label for="">End Date</label>  
<input type="date" v-model="AddEndDate" placeholder=""></p>
<p><label  for="">Provision</label>  
<input type="number" v-model="AddProvision" placeholder="Provision"></p>
<button @click="AddGuest()">Dodaj</button>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
    data: function () {
    return {
      sort: {
        key: '',
        isAsc: false,
       
      },
       Guests:[],
       Reservation:[],
       AddName:"",
       AddLastName:"",
       AddEmail:"",
       AddBirthDate:"",
       AddPostCode:"",
       AddAddress:"",
       AddCity:"",
       AddPhone:"",
       AddReservationNumber:"",
       AddPrice:"",
       AddStartDate:"",
       AddEndDate:"",
       AddProvision:"",


    }
  },
  created () {
   fetch('https://webapihostelsystem.azurewebsites.net/api/Guest?city=Wrocław&name=Piotr', {
  mode: 'cors',
  credentials: 'include'
})
    .then(res => res.json())
    .then(res => {
      this.Guests = res;
    })
       fetch('https://webapihostelsystem.azurewebsites.net/api/Reservation', {
  mode: 'cors',
  credentials: 'include'
}).then(res => res.json())
    .then(res => {
      this.Reservation = res;
    })
  },
   computed: {
    sortedItems () {
      const list = this.Guests.slice();  
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]

          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      
      return list;
    },
        sortedReservation () {
      const list = this.Reservation.slice();  
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]

          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      
      return list;
    }
  },
  methods: {




    sortedClass (key) {

      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
    },
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    AddGuest()
    {
var xhr = new XMLHttpRequest();
var date = new Date();
var timenow = JSON.stringify(date) 
console.log(timenow);
console.log(this.AddStartDate);



var url = "https://webapihostelsystem.azurewebsites.net/api/Guest";
xhr.open("POST", url, true ,);
xhr.setRequestHeader("Content-Type", "application/json");
xhr.onreadystatechange = function () {

};
var data = JSON.stringify({
  "$id": "1",
  "Id": 32,
  "Name": this.AddName,
  "LastName": this.AddLastName,
  "Email": this.AddEmail,
  "BirthDate": this.AddBirthDate,
  "PostCode": this.AddPostCode,
  "Adress": this.AddAddress,
  "City": this.AddCity,
  "Phone": this.AddPhone,
  "Reservations": [
    {
      "$id": "2",
      "ID": 17,
      "ReservationNumber": this.AddReservationNumber,
      "Price": this.AddPhone,
      "CreationDate": "2021-08-11T00:39:44.7213956+00:00" ,
      "StartDate": this.AddStartDate,
      "EndDate": this.AddEndDate,
      "Provision": this.AddProvision,
      "Guests": [
        null,
        {
          "$ref": "1"
        }
      ]
    }
  ]
});


xhr.send(data);


    }
  }
}
  </script>
<style>
body {
  background-color: white;
  padding: 10px;
}
table
{
  margin: auto;
}
tr, td, th, .th-class
{
  border: solid 1px black !important;
  padding: 5px 10px;
}
input
{
  width:100%;
}

</style>